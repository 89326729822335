import React from 'react';
import { Select, Text } from '@mantine/core';

export const SelectStream = (availableStreams, currentStream, setStream) => {
  function setStreamFromName(streamName) {
    setStream(availableStreams.find(s => s.name === streamName));
  }
  return (
    <div>
      <Text weight={700} align='center' size="md" color="dimmed">Stream</Text>
      <Select value={currentStream ? currentStream.name : undefined} onChange={setStreamFromName} placeholder='MyAppCafé Stream'
        data={availableStreams ? availableStreams.map((stream) => { return { value: stream.name, label: stream.name, group: (stream.region === 'eu-central-1' ? "Europa" : "USA") }; }) : []}>
      </Select>
    </div>
  );
};
