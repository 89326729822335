import React from 'react';
import { Grid, Skeleton, Slider } from '@mantine/core';
import { Text } from '@mantine/core';
import { DatePicker, TimeInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { locale } from './App';
import { CalendarIcon, ClockIcon, ClockHistory } from "./CustomIcons";


export const SelectTime = (currentMode, currentTime, setTime, clipDuration, setClipDuration, tTimePeriod, tPickDate) => {

  function parseDay(e) {
    const previous = dayjs(currentTime);
    const time = dayjs(e).set('hour', previous.hour()).set('minute', previous.minute()).set('second', previous.second());
    setTime(time.toDate());
  }
  function parseTime(e) {
    const previous = dayjs(currentTime);
    const time = dayjs(e).set('year', previous.year()).set('month', previous.month()).set('day', previous.day());
    setTime(time.toDate());
  }

  if (currentMode === 'Replay')
    return (
      <div>
        <Text weight={700} align="center" size="md" color="dimmed">{tTimePeriod}</Text>
        <Grid>
          <Grid.Col md={6} span={12}>
            <DatePicker
              icon={<CalendarIcon />}
              value={currentTime}
              onChange={parseDay}
              required
              clearable={false}
              locale={locale}
              placeholder={tPickDate}
              minDate={dayjs(new Date()).subtract(4, 'days').toDate()}
              maxDate={dayjs(new Date()).toDate()} />
          </Grid.Col>
          <Grid.Col md={6} span={12}>
            <TimeInput value={currentTime} icon={<ClockIcon />} onChange={parseTime} withSeconds />
          </Grid.Col>
        </Grid>
      </div>
    );
  if (currentMode === 'Clip') {

    return (
      <div>
        <Text weight={700} align="center" size="md" color="dimmed">{tTimePeriod}</Text>
        <Grid>
          <Grid.Col md={6} span={12}>
            <DatePicker
              icon={<CalendarIcon />}
              value={currentTime}
              required
              clearable={false}
              onChange={parseDay}
              locale={locale}
              placeholder={tPickDate}
              minDate={dayjs(new Date()).subtract(4, 'days').toDate()}
              maxDate={dayjs(new Date()).toDate()} />
          </Grid.Col>
          <Grid.Col md={3} span={6}>
            <TimeInput icon={<ClockHistory />} value={currentTime} onChange={parseTime} withSeconds />
          </Grid.Col>
          <Grid.Col md={3} span={6}>
            <Slider label={(value) => `${value}s`}
              value={clipDuration} min={10} max={240} onChange={setClipDuration} step={10}
              marks={[
                { value: 60, label: '60s' },
                { value: 120, label: '120s' },
                { value: 180, label: '180s' },
              ]} />
          </Grid.Col>
        </Grid>
      </div>
    );
  }
  return (
    <div>
      <Text weight={700} align="center" size="md" color="dimmed">{tTimePeriod}</Text>
      <Grid>
        <Grid.Col md={6} span={12}>
          <Skeleton height={34}></Skeleton>
        </Grid.Col>
        <Grid.Col md={6} span={12}>
          <Skeleton height={34}></Skeleton>
        </Grid.Col>
      </Grid>
    </div>
  );
};
