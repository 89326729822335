import React, { useEffect } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./video-custom.css";

export const VideoPlayer = (props) => {


  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { onReady, source } = props;

  useEffect(() => {
    const options = {
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      liveui: false,
      preload: 'auto',
      usingNativeControls: true,
      playbackRates: [0.25, 0.5, 1],
      suppressNotSupportedError: false,
      html5: {
        vhs: {
          overrideNative: true
        }
      },
    }

    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      });


    }
  }, [videoRef, onReady, source]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js vjs-4-3" />
    </div>
  );
}

export default VideoPlayer;