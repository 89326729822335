import React, { useState, useEffect } from 'react';


import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { withAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'; //does not work


import { MantineProvider, Image, Center, Grid, SimpleGrid, Collapse, Space, Button } from '@mantine/core';
import { Title, Loader, Text } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications'; // not working 
import dayjs from 'dayjs';
import { useViewportSize } from '@mantine/hooks';

import VideoPlayer from './VideoPlayer'
import { getAllStreams, getStreamingUrl, getClip } from './KinesisVideo';
import { SelectTime } from './SelectTime';
import { SelectMode } from './SelectMode';
import { SelectStream } from './SelectStream';
import { DownloadIcon, PlayButton } from './CustomIcons';

import { useTranslation } from "react-i18next";
import "./translations/i18n";

Amplify.configure(awsExports);

export const locale = navigator.language || navigator.userLanguage;
const App = () => {
  const { t } = useTranslation();

  dayjs.locale(locale)
  const [currentStream, setStream] = useState(undefined)
  const [allStreams, setAllStreams] = useState(undefined)
  const [currentMode, setMode] = useState('Live')
  const [currentTime, setTime] = useState(dayjs(new Date()).subtract(30, 'minutes').toDate())
  const [clipDuration, setClipDuration] = useState(30)
  const [isButtonDisabled, setButtonDisabeled] = useState(false)
  const [timeoutHandle, setTimeoutHandle] = useState(0)

  var timeout = 10 * 60 * 1000

  const userPrefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  const theme = userPrefersDark ? 'dark' : 'light'

  document.body.style.backgroundColor = theme === 'dark' ? '#1c2028' : 'white'

  const { height, width } = useViewportSize();

  const playerRef = React.useRef(null);

  useEffect(() => {
    async function queryStreams() {
      const streams = await getAllStreams()
      if (streams.length === 1) {
        setStream(streams[0])
      }
      setAllStreams(streams)
    }
    queryStreams()
  }, [])

  useEffect(() => {
    if (timeoutHandle !== 0) {
      window.clearTimeout(timeoutHandle)
      setTimeoutHandle(0)
    }
    if (playerRef.current) {
      playerRef.current.reset()
      if (!playerRef.current.paused())
        playerRef.current.pause()
    }

  }, [currentMode, currentStream, timeoutHandle])


  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // you can handle player events here
    player.on('error', () => {
      console.error('error while playing');
      player.reset()
      window.clearTimeout(timeoutHandle)
      setTimeoutHandle(0)
    });

  };


  const noDataNotification = () => {

  }


  const setNewSource = async () => {

    if (!currentStream || !currentMode) {
      return
    }

    if (currentMode === 'Clip') {
      setButtonDisabeled(true)
      try {
        await getClip(currentStream, currentTime, clipDuration)
      } catch (error) {
        console.error('error getting clip', error)
        return noDataNotification()
      } finally {
        setButtonDisabeled(false)
        return
      }

    }

    if (timeoutHandle !== 0) {
      window.clearTimeout(timeoutHandle)
      setTimeoutHandle(0)
    }
    const newHandle = window.setTimeout(() => {
      if (playerRef.current) {
        playerRef.current.reset()
        if (!playerRef.current.paused())
          playerRef.current.pause()
      }
    }, timeout)
    setTimeoutHandle(newHandle)

    if (currentMode !== 'Live' && (!(currentTime instanceof Date) || !(isFinite(currentTime)))) {
      return
    }
    if (!playerRef.current) {
      return
    }

    try {
      setButtonDisabeled(true)
      const newSource = await getStreamingUrl(currentStream, currentMode, currentTime)
      playerRef.current.src(newSource)
      // only way to get it to work in safari...
      window.setTimeout(() => {
        playerRef.current.play()
        setButtonDisabeled(false)
      }, 250)
    } catch (error) {
      console.error('error setting new source', error)
      setButtonDisabeled(false)
      return noDataNotification()
    }
  }

  const entryPoint = () => {
    if (!allStreams) {
      return (
        <Center style={{ width: width - 30, height: height - 30 }}>
          <Loader size="xl" variant='bars' color="teal" /><Space w={20}></Space><Title order={3}>{t("loadingAllStreams")}</Title>
        </Center>
      )
    }
    if (allStreams.length === 0) {
      return (
        <SimpleGrid cols={1}>
          <Title order={1}>{t("noStreams")}</Title>
          <Text color="dimmed">{t("needAccess")}</Text>
        </SimpleGrid>
      )
    }
    return (
      <NotificationsProvider>
        <Grid style={{ maxWidth: "1024px" }}>
          <Grid.Col span={12} my={3}>
            <Image
              src={require("./assets/MAC_Logo.png")}
              alt="Random unsplash image"
              height={80}
              fit='contain'
            />
            <Space h="xl" />
          </Grid.Col>
          <Grid.Col span={12} my={6}>
            <Title align='center' order={1}>MyAppCafé Videostream</Title>
            <Space h="xl" />
          </Grid.Col>
          <Grid.Col span={12} md={6} sm={6}>
            {SelectStream(allStreams, currentStream, setStream)}
          </Grid.Col>
          <Grid.Col span={12} md={6} sm={6}>
            {SelectMode(currentMode, setMode, t("mode"), t("chooseMode"))}
          </Grid.Col>
          <Grid.Col span={12}>
            <Collapse in={currentMode !== 'Live'} transitionTimingFunction='ease' transitionDuration={500}>
              {SelectTime(currentMode, currentTime, setTime, clipDuration, setClipDuration, t("timePeriod", t("pickDate")))}
            </Collapse>
          </Grid.Col>
          <Grid.Col span={12}>
            <Button fullWidth onClick={setNewSource} size='xl' leftIcon={currentMode === 'Clip' ? <DownloadIcon /> : <PlayButton />}
              disabled={isButtonDisabled || (!(currentStream && (currentMode === 'Live' || (currentMode === 'Replay' && currentTime) || (currentMode === 'Clip' && currentTime && clipDuration))))}
            >{currentMode === 'Clip' ? t("download") : t("play")}</Button>
          </Grid.Col>
          <Grid.Col span={12}>
            <VideoPlayer onReady={handlePlayerReady} />
          </Grid.Col>
        </Grid>
      </NotificationsProvider>
    )
  }

  return (
    <MantineProvider
      theme={{ colorScheme: theme }}>
      {/* <NotificationsProvider> */}
      <Center>
        {entryPoint()}
      </Center>
      {/* </NotificationsProvider> */}
    </MantineProvider>
  )
}

export default withAuthenticator(App, { hideSignUp: true, loginMechanisms: ['email'] });