export const TRANSLATIONS_EN = {
  loadingAllStreams: "Loading all available streams...",
  noStreams: "There no streams available for your user",
  needAccess: "Please contact technical support if you need access.",
  mode: "Mode",
  chooseMode: "Choose the mode",
  timePeriod: "Time period",
  play: "Play",
  download: "Download",
  pickDate: "Pick a date"
}