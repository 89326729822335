import React from 'react';
import { SegmentedControl } from '@mantine/core';
import { Text } from '@mantine/core';

export const SelectMode = (currentMode, setMode, tMode, tChooseMode) => {
  return (
    <div>
      <Text weight={700} align="center" size="md" color="dimmed">{tMode}</Text>
      <SegmentedControl fullWidth value={currentMode} onChange={setMode} label={tChooseMode}
        data={[
          { label: 'Live', value: 'Live' },
          { label: 'Replay', value: 'Replay' },
          { label: 'Clip', value: 'Clip' }
        ]}></SegmentedControl>
    </div>
  );
};
