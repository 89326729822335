export const TRANSLATIONS_DE = {
  loadingAllStreams: "Lade alle verfügbaren Streams...",
  noStreams: "Leider sind für Ihren User keine Streams verfügbar",
  needAccess: "Bitte wenden Sie sich an den technischen Support, wenn Sie Zugang benötigen.",
  mode: "Modus",
  chooseMode: "Wählen Sie den Modus",
  timePeriod: "Zeitraum",
  play: "Abspielen",
  download: "Herunterladen",
  pickDate: "Datum auswählen"
}