import {
  Auth
} from "aws-amplify";
import {
  GetDataEndpointCommand,
  KinesisVideoClient,
  ListStreamsCommand
} from "@aws-sdk/client-kinesis-video";
import {
  KinesisVideoArchivedMediaClient,
  GetHLSStreamingSessionURLCommand,
  //GetClipCommand
} from "@aws-sdk/client-kinesis-video-archived-media";
import dayjs from "dayjs";
const AWS = require('aws-sdk')
export const getAllStreams = async () => {
  const credentials = await Auth.currentCredentials();

  const session = await Auth.currentSession();
  const idToken = session.getAccessToken();
  const payload = idToken.decodePayload();

  const isAdmin = "cognito:groups" in payload && payload["cognito:groups"].includes("admin")
  const hasVideoAccess = "cognito:groups" in payload && payload["cognito:groups"].includes("video")
  const userInfo = await Auth.currentUserInfo()

  const allowedStreams = "custom:streams" in userInfo.attributes ? userInfo.attributes["custom:streams"].split(",") : []

  if ((!hasVideoAccess || allowedStreams.length === 0) && !isAdmin) {
    console.warn('user is not admin, and either is not in "video" group or attribute "custom:streams" is not set on the cognito user')
    return null;
  }

  const euClient = new KinesisVideoClient({
    region: "eu-central-1",
    credentials: credentials
  });

  const allEuStreams = await euClient.send(new ListStreamsCommand({
    MaxResults: 10000
  }));
  var allStreams = allEuStreams.StreamInfoList.map(streamInfo => {
    return {
      name: streamInfo.StreamName,
      region: 'eu-central-1'
    };
  });

  const usClient = new KinesisVideoClient({
    region: "us-east-1",
    credentials: credentials
  });

  const allUsStreams = await usClient.send(new ListStreamsCommand({
    MaxResults: 10000
  }));
  allStreams = [...allStreams, ...allUsStreams.StreamInfoList.map(streamInfo => {
    return {
      name: streamInfo.StreamName,
      region: 'us-east-1'
    };
  })];

  if (allowedStreams.includes("*") || isAdmin) return allStreams;
  const userStreams = allStreams.filter(s => allowedStreams.some(a => s.name.startsWith(a)))
  return userStreams
};

export const getEndpoint = async (stream, apiName) => {
  const credentials = await Auth.currentCredentials();
  const client = new KinesisVideoClient({
    region: stream.region,
    credentials: credentials
  });

  const endpoint = await client.send(new GetDataEndpointCommand({
    StreamName: stream.name,
    APIName: apiName
  }))

  return endpoint.DataEndpoint;
}

export const getClip = async (stream, currentTime, clipDuration) => {

  const clipOptions = {
    ClipFragmentSelector: {
      FragmentSelectorType: 'SERVER_TIMESTAMP',
      TimestampRange: {
        EndTimestamp: dayjs(currentTime).add(clipDuration, 'seconds').toDate(),
        StartTimestamp: currentTime
      }
    },
    StreamName: stream.name
  }
  const endpoint = await getEndpoint(stream, 'GET_CLIP');
  const credentials = await Auth.currentCredentials();
  const options = {
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: stream.region,
    endpoint
  }
  const kinesisVideo = new AWS.KinesisVideoArchivedMedia(options)

  const response = await kinesisVideo.getClip(clipOptions).promise()
  saveByteArray(`${stream.name}_${dayjs(currentTime).format("YYYY-MM-DD_HH_MM_SS")}.mp4`, response.Payload)

  /* THE NEW AWS SDK (V3) DOES NOT RETURN A BYTE ARRAY
  ON THE SAME OPERATION BUT A READABLE STREAM, AND THAT IS
  A PITA ON FRONTEND APPLICATIONS. USING AWS SDK V2 FOR NOW */

  // const client = new KinesisVideoArchivedMediaClient({
  //   region: stream.region,
  //   credentials,
  //   endpoint
  // });

  // const command = new GetClipCommand(clipOptions);
  // const response = await client.send(command);

  // console.log('get clip respose', response)

  // return response.Payload

}

function saveByteArray(fileName, byte) {
  const blob = new Blob([byte], {
    type: 'video/mp4'
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
}

export const getStreamingUrl = async (stream, streamingMode, date) => {

  const endpoint = await getEndpoint(stream, 'GET_HLS_STREAMING_SESSION_URL');
  const credentials = await Auth.currentCredentials();
  const client = new KinesisVideoArchivedMediaClient({
    region: stream.region,
    credentials,
    endpoint
  });


  const modeName = streamingMode === 'Live' ? 'LIVE' : streamingMode === 'Replay' ? 'LIVE_REPLAY' : undefined
  if (!modeName) throw new Error('unknown streaming mode');

  const command = new GetHLSStreamingSessionURLCommand({
    StreamName: stream.name,
    PlaybackMode: modeName,
    HLSFragmentSelector: {
      FragmentSelectorType: 'SERVER_TIMESTAMP',
      TimestampRange: modeName === 'LIVE' ? undefined : {
        StartTimestamp: date
      }
    },
    ContainerFormat: 'FRAGMENTED_MP4',
    DiscontinuityMode: 'ALWAYS',
    DisplayFragmentTimestamp: 'ALWAYS',
    Expires: 10 * 60
  })
  try {
    const response = await client.send(command);
    return {
      src: response.HLSStreamingSessionURL,
      type: 'application/x-mpegURL'
    }
  } catch (error) {
    console.error('could not get streaming session url', error)
    return undefined
  }
}